import type { GetStaticProps, NextPage } from "next";
import HomeView from "../domains/home/Home";
import FooterLessLayout from "src/domains/layout/FooterLessLayout";
import NoticeModal from "src/domains/shared/components/NoticeModal";
import { makeModalConstants } from "src/domains/shared/constants/constants";
import useLocale from "src/domains/shared/hooks/localeHook/useLocale";
//

const Home: NextPage = () => {
  const [locale, setLocale] = useLocale();
  return (
    <FooterLessLayout>
      <HomeView />
      {/* <NoticeModal modals={makeModalConstants(locale.locale)} /> */}
    </FooterLessLayout>
  );
};

export const getStaticProps: GetStaticProps = () => {
  return {
    props: {},
  };
};

export default Home;
