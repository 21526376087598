import { useEffect } from "react";
import Image from "next/image";
import { css } from "@emotion/react";
import { AiOutlineCheck } from "react-icons/ai";
import { Page, StepScroll } from "fullpage-scroll-component";
import { COLOR } from "../shared/constants/style";
import ChannelService from "src/domains/shared/utils/ChannelService";
import { useAuth } from "../shared/hooks/user";
import { CHANNELTALK_KEY } from "../shared/constants/constants";
import ProjectFooter from "../projects/ProjectFooter";

const HomeView = () => {
  const { currentUser } = useAuth();

  useEffect(() => {
    if (!currentUser) return;

    const channelTalk = new ChannelService();
    if (currentUser) {
      const {
        attributes: { name, email, phone_number },
      } = currentUser;
      channelTalk.boot({
        pluginKey: CHANNELTALK_KEY,
        memberId: name,
        profile: {
          name,
          email,
          mobileNumber: phone_number,
        },
      });
    } else {
      channelTalk.boot({
        pluginKey: CHANNELTALK_KEY,
      });
    }
    return () => {
      channelTalk.shutdown();
    };
  }, [currentUser]);

  return (
    <section css={relative}>
      <StepScroll>
        <Page>
          <div css={firstPage.firstSection}>
            <div css={firstPage.mainCardSection}>
              <Image
                src="/main/main_bg.png"
                alt="Dronsquare testing image"
                layout="fill"
                objectFit="cover"
              />
            </div>
            <div css={firstPage.titleContainer}>
              <div css={firstPage.titleText}>
                드론 기반 인프라 자산관리 플랫폼
              </div>
              <div css={firstPage.titleSubText}>
                드론 스퀘어에서 제공하는 다양한 어플리케이션을 통해 프로젝트를
                효율적으로 관리해주세요.
              </div>
            </div>
          </div>
        </Page>
        <Page>
          <div css={secondPage.videoSection}>
            <div css={secondPage.videoTitle}>
              드론 스퀘어를 도입해
              <br />
              공간 데이터를
              <br />
              가치있는 비즈니스 인텔리전스로 전환하세요.
            </div>
            <div css={secondPage.opacityStyle}>
              <video
                css={secondPage.videoStyle}
                src="/main/dronesquare-wall.mp4"
                autoPlay
                loop
                muted
              />
            </div>
          </div>
        </Page>
        <Page>
          <div css={thirdPage.innerPage}>
            <div css={thirdPage.subCardTitleText}>
              드론 스퀘어로 인프라 자산 디지털 전환, <br /> 드론 스퀘어로부터
              인프라 자산 스마트 관리/분석
            </div>
            <div css={thirdPage.SubCardTextContainer}>
              <div css={thirdPage.subCardTextDiv}>
                <div css={thirdPage.subTextDiv}>
                  <AiOutlineCheck size={20} css={thirdPage.subCheckIcon} />
                  <div css={thirdPage.subText}>
                    드론 공간데이터의 프로젝트 단위별 구축과 프로젝트들의
                    <br />
                    시계열적 연계로 효과적인 프로젝트 관리도구 제공
                  </div>
                </div>
                <div css={thirdPage.subTextDiv}>
                  <AiOutlineCheck size={20} css={thirdPage.subCheckIcon} />
                  <div css={thirdPage.subText}>
                    데이터 가공 작업이 필요 없는 드론 원 데이터 기반의
                    <br />
                    신속한 의사결정 지원
                  </div>
                </div>
                <div css={thirdPage.subTextDiv}>
                  <AiOutlineCheck size={20} css={thirdPage.subCheckIcon} />
                  <div css={thirdPage.subText}>
                    드론 원/가공 데이터를 연계하여 각종 측정 및 정밀 점검
                    <br />
                    수행 시 효율성 극대화
                  </div>
                </div>
              </div>
              <Image
                width={500}
                height={400}
                priority={true}
                src="/main/main_section2.png"
                alt="Dronsquare testing image"
                objectFit="cover"
              />
            </div>
          </div>
          <div css={thirdPage.footerStyle}>
            <ProjectFooter />
          </div>
        </Page>
      </StepScroll>
    </section>
  );
};

const firstPage = {
  firstSection: css`
    position: relative;
    z-index: 2;
  `,
  mainCardSection: css`
    position: relative;
    width: 100vw;
    height: 100vh;
  `,
  titleContainer: css`
    position: absolute;
    left: 20%;
    top: 16%;
    background: none;
  `,
  titleText: css`
    line-height: 1.5;
    font-size: 2.75rem;
    font-weight: 600;
  `,
  titleSubText: css`
    color: ${COLOR.Gray300};
    font-weight: 500;
    font-size: 1.25rem;
    margin: 0 auto;
    line-height: 1.5;
    padding-bottom: 5rem;
  `,
};

const secondPage = {
  videoSection: css`
    position: relative;
    height: 100vh;
    width: 100vw;
  `,
  videoTitle: css`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-weight: 700;
    font-size: 3rem;
    z-index: 10;
  `,
  opacityStyle: css`
    opacity: 0.7;
  `,
  videoStyle: css`
    object-fit: cover;
    z-index: 0;
  `,
};

const thirdPage = {
  footerStyle: css`
    width: 100%;
    position: absolute;
    bottom: 0%;
    z-index: 1000;
  `,

  subCardTitleText: css`
    text-align: center;
    font-weight: 600;
    font-size: 2.5rem;
  `,

  SubCardTextContainer: css`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
  `,

  subCardTextDiv: css`
    margin-right: 8rem;
  `,

  subTextDiv: css`
    display: flex;
    margin-top: 50px;
  `,

  subText: css`
    font-size: 1.2rem;
    margin-left: 20px;
  `,

  subCheckIcon: css`
    margin-top: 12px;
    align-items: center;
  `,
  innerPage: css`
    position: relative;
    padding-top: 120px;
    background-color: black;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    opacity: 1;
  `,
};

const relative = css`
  position: relative;
`;

export default HomeView;
