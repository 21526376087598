import styled from "@emotion/styled";
import { PropsWithChildren } from "react";
import HeaderRsuit from "../shared/components/Header/HeaderRsuit";
import { COLOR } from "../shared/constants/style";
import { css } from "@emotion/react";

const FooterLessLayout: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <div css={hiddenScroll}>
      <HeaderRsuit />
      <HomeContainer>{children}</HomeContainer>
    </div>
  );
};

const hiddenScroll = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100vh;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

const HomeContainer = styled.div`
  color: ${COLOR.White100};
  height: 100%;
`;

export default FooterLessLayout;
